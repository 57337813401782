<template>
	<a
			v-if="this.options.isExternalLink"
			:href="this.options.link"
			:target="this.options.link.startsWith('#') ? '_self' : '_blank'"
			:class="this.options.class ?? ''"
			@click="this.$emit('clickOnLink')"
			v-on="this.options.link.startsWith('#') ? {click: jumpTo} : {}"
	>
		<slot name="prefix"></slot>
		{{ this.options.title }}
		<slot name="suffix"></slot>
	</a>

	<router-link
			v-else
			:to="{ path: this.options.link }"
			:class="this.options.class ?? ''"
			@click="this.$emit('clickOnLink')"
	>
		<slot name="prefix"></slot>
		{{ this.options.title }}
		<slot name="suffix"></slot>
	</router-link>
</template>

<script lang="ts">
import {defineComponent, PropType} from "vue";
import {VLinkInterface} from "./Interfaces/VLinkInterface";

export default defineComponent({
	name: "VLink",
	emits: ['clickOnLink'],
	props: {
		options: {
			type: Object as PropType<VLinkInterface>,
			required: true,
		},
	},
	methods: {
		jumpTo(e) {
			e.preventDefault();
			const href = e.target.getAttribute('href');
			const element = document.querySelector(href);
			if(element) {
				const elementPosition = element.getBoundingClientRect().top + window.scrollY;
				window.scrollTo({
					top: elementPosition - 20,
					behavior: "smooth",
				});
			}
		}
	}
});
</script>

<style scoped>
</style>
