import {defineComponent} from "vue";
import {LayoutCssVariables} from "../Utils/LayoutCssVariables";
import {ButtonUnitShape} from "../Components/Units/Interfaces/ButtonUnitShape";

export default defineComponent({
	computed: {
		primaryColor(): string {
			return LayoutCssVariables.page.profile;
		},
		primaryColorVar(): string {
			return `var(--${LayoutCssVariables.page.profile})`;
		},
		ButtonUnitShape(): typeof ButtonUnitShape {
			return ButtonUnitShape;
		},
		buttonTextColor(): string {
			return '#ffffff';
		},
	},
	methods: {
		capitalizeFirstLetter(val) {
			return String(val).charAt(0).toUpperCase() + String(val).slice(1);
		}
	}
});
